exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-features-app-movil-tsx": () => import("./../../../src/pages/features/appMovil.tsx" /* webpackChunkName: "component---src-pages-features-app-movil-tsx" */),
  "component---src-pages-features-carga-completa-tsx": () => import("./../../../src/pages/features/cargaCompleta.tsx" /* webpackChunkName: "component---src-pages-features-carga-completa-tsx" */),
  "component---src-pages-features-e-cmr-tsx": () => import("./../../../src/pages/features/eCmr.tsx" /* webpackChunkName: "component---src-pages-features-e-cmr-tsx" */),
  "component---src-pages-features-empleados-tsx": () => import("./../../../src/pages/features/empleados.tsx" /* webpackChunkName: "component---src-pages-features-empleados-tsx" */),
  "component---src-pages-features-facturacion-tsx": () => import("./../../../src/pages/features/facturacion.tsx" /* webpackChunkName: "component---src-pages-features-facturacion-tsx" */),
  "component---src-pages-features-paqueteria-tsx": () => import("./../../../src/pages/features/paqueteria.tsx" /* webpackChunkName: "component---src-pages-features-paqueteria-tsx" */),
  "component---src-pages-features-portal-clientes-tsx": () => import("./../../../src/pages/features/portalClientes.tsx" /* webpackChunkName: "component---src-pages-features-portal-clientes-tsx" */),
  "component---src-pages-features-vehiculos-tsx": () => import("./../../../src/pages/features/vehiculos.tsx" /* webpackChunkName: "component---src-pages-features-vehiculos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-politica-cookies-tsx": () => import("./../../../src/pages/legal/politicaCookies.tsx" /* webpackChunkName: "component---src-pages-legal-politica-cookies-tsx" */),
  "component---src-pages-legal-politica-privacidad-tsx": () => import("./../../../src/pages/legal/politicaPrivacidad.tsx" /* webpackChunkName: "component---src-pages-legal-politica-privacidad-tsx" */),
  "component---src-pages-legal-terminos-condiciones-tsx": () => import("./../../../src/pages/legal/terminosCondiciones.tsx" /* webpackChunkName: "component---src-pages-legal-terminos-condiciones-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-prices-tsx": () => import("./../../../src/templates/prices.tsx" /* webpackChunkName: "component---src-templates-prices-tsx" */)
}

